import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

const breadcrumbMap = {
  // 'Dashboard': {name:'Inbox',path:"/Dashboard/Dashboard"},
  'Movie': {name:'Videos',path:"/Movie/Movie"},
  'CreateMovie': {name:'Create Videos',path:"/Movie/CreateMovie"},
  'EditMovie': {name:'Edit Videos',path:"/Movie/EditMovie"},

  'UpcomingMovie': {name:'Coming Soon Video',path:"/Movie/UpcomingMovie"},
  'CreateUpcomingMovie': {name:'Create Coming Soon Video',path:"/Movie/CreateUpcomingMovie"},
  'EditUpcomingMovie': {name:'Edit Coming Soon Video',path:"/Movie/EditUpcomingMovie"},

  'Series': {name:'Series',path:"/Series/Series/"},
  'CreateSeries': {name:'Create Series',path:"/Series/CreateSeries/"},
  'EditSeries': {name:'Edit Series',path:"/Series/EditSeries/"},

  'SeriesDetails': {name:'Series Details' , path:"/Series/Series/SeriesDetails/"},

  'Season': {name:'Season',path:"/Season/Season/"},
  'CreateSeason': {name:'Create Season',path:"/Season/CreateSeason/"},
  'EditSeason': {name:'Edit Season',path:"/Season/EditSeason/"},

  'WebEpisode': {name:'Web Episode',path:"/WebEpisode/WebEpisode/"},
  'CreateWebEpisode': {name:'Create Web Episode',path:"/WebEpisode/CreateWebEpisode/"},
  'EditWebEpisode': {name:'Edit Web Episode',path:"/WebEpisode/EditWebEpisode/"},

  'SSEpisode': {name:'Short Episode',path:"/SSEpisode/SSEpisode/"},
  'CreateSSEpisode': {name:'Create Short Episode',path:"/SSEpisode/CreateSSEpisode/"},
  'EditSSEpisode': {name:'Edit Short Episode',path:"/SSEpisode/EditSSEpisode/"},

  'Collaborator' : {name:"Collaborator" , path :"/Collaborator/Collaborator/"},
  'CreateCollaborator' : {name:"Create Collaborator" , path :"/Collaborator/CreateCollaborator/"},
  'EditCollaborator' : {name:"Edit Collaborator" , path :"/Collaborator/EditCollaborator/"},

  'ContentAdvisory' : {name:"Content Advisory" , path :"/ContentAdvisory/ContentAdvisory/"},
  'CreateContentAdvisory' : {name:"Create Content Advisory" , path :"/ContentAdvisory/CreateContentAdvisory/"},
  'EditContentAdvisory' : {name:"Edit Content Advisory" , path :"/ContentAdvisory/EditContentAdvisory/"},


  'TVCategory': {name:'Streaming Category',path:"/LiveStreaming/TVCategory"},
  'TVChannel': {name:'Channel',path:"/LiveStreaming/TVChannel"},
  'SliderBanner': {name:'Slider Banner',path:"/SliderBanner/SliderBanner"},
  'CreateSliderBanner': {name:'Create Slider Banner',path:"/SliderBanner/CreateSliderBanner"},
  'EditSliderBanner': {name:'Edit Slider Banner',path:"/SliderBanner/EditSliderBanner"},

  'Customer': {name:'Customers',path:"/Customer/Customer"},
  'Transactions': {name:'Revenue Report',path:"/Transactions/Transactions/"},
  'AllTransaction' : {name :"All Transactions" , path:"/AllTransaction/AllTransaction"},
  'TransactionsDetails': {name:'Transactions Details',path:"/Transactions/TransactionsDetails/TransactionsDetails"},
  'WatchHours': {name:'Watch-hours',path:"/WatchHours/WatchHours/"},

  'Complaint': {name:'Complaints',path:"/Complaint/Complaint/"},
  'Subscriptions': {name:'Transactions',path:"/Subscriptions/Subscriptions/"},
  'EditSubscriptions': {name:'Edit Subscriptions',path:"/Subscriptions/EditSubscriptions/"},
  "reports" : {name:"Reports" , path:"/reports/reports"}, 
  'reports_download' : {name:"Reports Download" , path :"/reports/reports_download/"},
  'SubAdmin': { name: 'Sub Admin', path: "/Masters/SubAdmin/SubAdmin" },
  'CreateSubAdmin': { name: 'Create Sub Admin', path: "/Masters/SubAdmin/CreateSubAdmin" },
  'EditSubAdmin': { name: 'Edit Sub Admin', path: "/Masters/SubAdmin/EditSubAdmin" },

  'FilmFestivalPendingData' : {name:"Film Festival Pending Data" , path:"/FilmFestivalPendingData/FilmFestivalPendingData"},
  'FilmFestivalShortListData' : {name:"Film Festival Short List Data" , path:"/FilmFestivalShortListData/FilmFestivalShortListData"},
  'FilmFestivalRejectedData' : {name:"Film Festival Rejected Data" , path:"/FilmFestivalRejectedData/FilmFestivalRejectedData"},
  'FilmFestivalNominatedData' : {name:"Film Festival Nominated Data" , path:"/FilmFestivalNominatedData/FilmFestivalNominatedData"},

  'FilmFestivalFormDetails' : {name:"Film Festival Detail" , path:"/FilmFestivalFormData/FilmFestivalFormDetails/FilmFestivalFormDetails"},

  'PaymentGateWay': { name: 'Payment Gateways', path: "Masters/PaymentGateWay" },

  'Coupon': {name:'Promocode',path:"/Coupon/Coupon"},
  'PromocodeHistory': {name:'Promocode History',path:"/Coupon/PromocodeHistory"},
  'Advertisers': {name:'Advertisers',path:"/Advertisers/Advertisers/"},
  'Advertisement': {name:'Advertisement',path:"/Advertisers/Advertisement/"},
  'Masters': {name:'Masters',path:"/Masters/Category/"},
  'Avatar': {name:'Avatar',path:"/Masters/Avatar/"},
  'Category': {name:'Category',path:"/Masters/Category/Category/"},
  'CreateCategory': {name:'Create Category',path:"/Masters/Category/CreateCategory/"},
  'EditCategory': {name:'Edit Category',path:"/Masters/Category/EditCategory/"},

  'DistributorsFormData' : {name:"Distributors" , path:"/DistributorsFormData/DistributorsFormData/"},
  'DistributorsFormDetails' : {name:'form Details' ,path :"/DistributorsFormData/DistributorsFormDetails/DistributorsFormDetails/"},

  'SubCategory': {name:'Sub Category',path:"/Masters/SubCategory/SubCategory"},
  'CreateSubCategory': {name:'Create Sub Category',path:"/Masters/SubCategory/CreateSubCategory"},
  'EditSubCategory': {name:'Edit Sub Category',path:"/Masters/SubCategory/EditSubCategory"},

  'Language': {name:'Language',path:"/Masters/Language/Language"},
  'CreateLanguage': {name:'Create Language',path:"/Masters/Language/CreateLanguage"},
  'EditLanguage': {name:'Edit Language',path:"/Masters/Language/EditLanguage"},

  'Genre': {name:'Genre',path:"/Masters/Genre/Genre"},
  'CreateGenre': {name:'Create Genre',path:"/Masters/Genre/CreateGenre"},
  'EditGenre': {name:'Edit Genre',path:"/Masters/Genre/EditGenre"},

  
  'Cast': {name:'Cast',path:"/Masters/Cast/Cast/"},
  'CreateCast': {name:'Create Cast',path:"/Masters/Cast/CreateCast/"},
  'EditCast': {name:'Edit Cast',path:"/Masters/Cast/EditCast/"},

  'Country': {name:'Country',path:"/Masters/Country/Country/"},
  'CreateCountry': {name:'Create Country',path:"/Masters/Country/CreateCountry/"},
  'EditCountry': {name:'Edit Country',path:"/Masters/Country/EditCountry/"},

  'SubAdmin': {name:'Sub Admin',path:"/Masters/SubAdmin/"},
  'Settings': {name:'Settings',path:"/Settings/AboutUs/"},
  'AboutUs': {name:'About Us',path:"/Settings/AboutUs/"},
  'PrivacyPolicy': {name:'Privacy Policy',path:"/Settings/PrivacyPolicy/"},
  'TermsConditions': {name:'Terms & Conditions',path:"/Settings/TermsConditions/"},
  'RefundPolicy': {name:'Refund Policy',path:"/Settings/RefundPolicy/"},
  'ContactUs': {name:'Contact Us',path:"/Settings/ContactUs/"},
  'AppSetting': {name:'App Setting',path:"/Settings/AppSetting/"},
  'UserLogs': {name:'User Logs',path:"/Settings/UserLogs/"},
  'MovieDetails': {name:'Movie Details',path:"/Movie/MovieDetails"},
  'EpisodeDetails': {name:'Web Series Episode Details',path:"/WebEpisode/WebEpisode/EpisodeDetails"},
  'EpisodeDetails': {name:'Super Short Episode Details',path:"/SSEpisode/SSEpisode/EpisodeDetails"},
  'WebSeries': {name:'TV Shows',path:"/WebSeries/Series/"},
  'CustomerDetail': {name:'Customer Detail',path:"/Customer/CustomerDetail/CustomerDetail"},
  'LiveStreaming': {name:'Live Streaming',path:"/LiveStreaming/TVCategory"},
  'MoviesDownloaded': {name:'Movies Downloaded',path:"/Customer/CustomerDetail/MoviesDownloaded"},
  'MoviesWatched': {name:'Movies Watched',path:"/Customer/CustomerDetail/MoviesWatched"},
  'MoviesDownload': {name:'Movies Download',path:"/Customer/CustomerDetail/MoviesDownload"},
  'Profile': {name:'Profile',path:"/Dashboard/Profile/"},
  // 'Layout': {name:'',path:"/Dashboard/Dashboard"},
  'Notifications': {name:'Notifications',path:"/Notifications/Notifications"},
  'CreateNotifications': {name:'Create Notifications',path:"/Notifications/CreateNotifications"},
  'EditNotifications': {name:'Edit Notifications',path:"/Notifications/EditNotifications"},

  
};



function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function Page() {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((x) => x);
  const pathnames = paths[0]=="Dashboard"?[]:[...new Set(paths)];

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <LinkRouter underline="hover" color="inherit" to="/Dashboard/Dashboard">
        Dashboard
      </LinkRouter>
      {/* {pathnames.map((value, index) => {
        const last = index >= pathnames.length - 2;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {pathnames[index]}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {pathnames[index]}
          </LinkRouter>
        );
      })} */}
      {pathnames.map((ele,index)=>index!=pathnames.length-1?
      <LinkRouter underline="hover" color="inherit" to={breadcrumbMap?.[ele]?.path} key={breadcrumbMap?.[ele]?.path}>
      {breadcrumbMap?.[ele]?.name}
          </LinkRouter>:
          
<Typography color="text.primary" key={breadcrumbMap?.[ele].path}>
            {breadcrumbMap?.[ele].name}
          </Typography>)
          }
    </Breadcrumbs>
  );
}

export default function RouterBreadcrumbs() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (

      <Box sx={{ display: 'flex', flexDirection: 'column',ml:2 }} fontSize={"12px"}>
        <Routes>
          <Route  path="*" element={<Page />} />
        </Routes>
     
      </Box>
   
  );
}
