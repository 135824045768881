import { useSelector } from "react-redux";

export const TableData = () => {
  const rights = useSelector((state) => state.layout.rights);
  return {
    tableTitle: "Coming Soon",
    deleteAccess: "true",
    tableHead: [
      {
        id: "title",
        label: "Videos Name",
      },
      {
        id: "poster",
        label: "Image",
        isImage: true,
      },
      {
        id: "subcategory_id",
        label: "Category",

      },

      {
        id: "language",
        label: "Language",
      },
      {
        id: "movieViews",
        label: "Views",
      },
      // ,
      // {
      //   id: 'movieLikes',
      //   label: 'Likes',
      // }

      {
        id: "release_date1",
        label: "Release Date",
        isSpecial: true,
        align: "left",
      },

      {
        id: "edit",
        label: "Update",
        access: "true",
      },
    ],
    tableBody: [],
    filterColumn: [
      {
        id: "2",
        title: "Language",
        name: "movie_language",
        options: ["English", "Hindi", "Gujarati"],
      },

      // {
      //   id: "4",
      // title: "Video Category",
      // name: "movie_category",
      // options:["Admin","Distributor"],
      // }
    ],
  };
};
