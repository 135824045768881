import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import UpcomingMovie from "../components/Movie/UpcomingMovie/UpcomingMovie";
// import Subscriptions from '../components/Subscription/Subscriptions';
function UpcomingMovieModule() {
  return (
    <div className="App">

      <Routes>
        {/* < Route path='/SliderBanner' element={<Layout children={Subscriptions()}/>}/> */}
        < Route path='/UpcomingMovie' element={<Layout children={UpcomingMovie()} />} />
        < Route path='/CreateUpcomingMovie' element={<Layout children={UpcomingMovie()} />} />
        < Route path='/EditUpcomingMovie' element={<Layout children={UpcomingMovie()} />} />
      </Routes>
    </div>
  );
}

export default UpcomingMovieModule;
