import React from "react";
export const FormStructure = (isEdit) => {
  return [
    {
      id: "1",
      type: "select",
      title: "Category",
      name: "category_id",
      options: ["English", "Hindi", "Gujarati"],
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Sub Category",
      name: "subcategory_id",
      options: ["English", "Hindi", "Gujarati"],
      required: true,
    },
    {
      id: "3",
      type: "inputBox",
      title: "Video Title",
      name: "title",
      maxLength: "25",
      regex: /^[a-zA-Z0-9\s\&]+$/,
      isCapitalise: true,
      required: true,
    },
    {
      id: "4",
      type: "select",
      title: "Language",
      name: "language_id",
      options: ["English", "Hindi", "Gujarati"],
      required: true,
    },
    {
      id: "5",
      type: "select_multiple",
      title: "Genre",
      name: "genre",
      maxSelections: "6",
      options: ["English", "Hindi", "Gujarati"],
      errorText: "You can select max 6 genre at a time",
      required: true,
    },

    {
      id: "6",
      type: "inputBox",
      variant: "date",
      title: "Publish Date",
      min: new Date().toISOString().split("T")[0],
      name: "release_date",
      // required: true,
    },
    {
      id: "7",
      type: "select",
      title: "Video Type",
      name: "video_type",
      options: ["Movie", "Series"],
      required: true,
    },
    {
      id: "8",
      type: isEdit ? "lockedInput" : "inputBox",
      title: "Trailer URL",
      endsWith: ".mp4",
      errorText: "File extension should be .mp4  only",
      name: "trailer_url",
      required: true,
    },
    {
      id: "9",
      type: "inputBox",
      title: "Duration",
      name: "trailer_duration",
      size: "6",
      disabled: true,
      required: true,
    },
    {
      id: "10",
      type: "description",
      title: "Description",
      name: "description",
      required: true,
      size: "12",
    },

    {
      id: "11",
      type: "image",
      title: "Browse Thumbnail",
      subtitle: "(Resolution : 910 x 1280) *",
      name: "thumbnail",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      subsubtitle: "Max File Size 1MB",
      required: true,
    },
    {
      id: "12",
      type: "image",
      subtitle: "(Resolution : 1280 x 720) *",
      title: "Browse Poster",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      subsubtitle: "Max File Size 1MB",
      name: "poster",
      size: 4,
      required: true,
    },
    {
      id: "13",
      type: "button",
      title: "Create",
    },
  ];
};
