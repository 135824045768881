import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  episode_create,
  episode_delete,
  episode_update,
  all_episode_list,
  // episode_audio_file_delete,
  // episode_subtitle_delete

} from "../../../actions/WebSeries/episode";
import Reload from "../../utils/Reload"

import {
  subtitle_delete,
  audio_file_delete,

} from "../../../actions/subtitle_delete";
// import { all_season_list } from "../../../actions/WebSeries/season";
// import { all_advertisement_list } from "../../../actions/Advertiser/advertisement";
import { all_series_list } from "../../../actions/WebSeries/series";
import { all_language_list } from "../../../actions/Masters/language";
import { all_cast_list } from "../../../actions/Masters/cast";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Popup from "../../utils/Popup";
import Export from "../../utils/Export";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_season_list } from "../../../actions/WebSeries/season";
import { video_link_delete } from "../../../actions/Movie/movie";
export default function SSEpisode() {
  const user = useSelector((state) => state.layout.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rights = useSelector((state) => state.layout.right);
  const role = useSelector((state) => state?.layout?.role);
  const location = useLocation();
  const tempTableData = {
    ...TableData(),
    deleteRecord: episode_delete,
    updateRecord: episode_update,
    disableDelete: role !== "Distributor" ? false : true,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  const [open, setOpen] = useState(false);
  const [adPosition, setAdPosition] = useState();
  const [flag, setFlag] = useState(false);

  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
  const [subTitleID, setsubTitleID] = useState("");
  const [audioFileId, setAudioFileId] = useState("")

  const [result, setResult] = useState(undefined);

  const [content, setPopupContent] = useState("");
  const [openAdError, setOpenAdError] = useState(false);

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );

  const formTitle = [
    "Episode Details",
    "Episode Resolution",
    // "Audio Files",
    "Episode URLs",
    "Age Restrictions & Downloads",
    // role != "Producer" && "Advertisement Details",
  ].filter((e) => e);
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  const path = location?.pathname.split("/")[2];

  useEffect(() => {
    setView(path != "SSEpisode" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      }
    );
    setIsEdit(path == "EditSSEpisode");
  }, [location]);

  // useMemo(() => {
  //   setTableData({ ...tempTableData });
  // }, [rights]);
  const episodes = useSelector((state) => state.webseries.episodes);
  // const advertisements = useSelector(
  //   (state) => state.advertisers.advertisements
  // );
  const language = useSelector((state) => state.masters.languages);
  const seasons = useSelector((state) => state.webseries.season);
  // console.log(seasons, "10000");
  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);
    data.append("series_type","Super Short Series")
    if(episodes?.statuscode !=200 || episodes?.data?.series_type != "Super Short Series") dispatch(all_episode_list(data));
    if(series?.statuscode !=200 ) dispatch(all_series_list(data));
    if(casts?.statuscode !=200) dispatch(all_cast_list());
    // if(genre?.statuscode !=200) dispatch(all_genre_list());
    if(language?.statuscode !=200) dispatch(all_language_list());
    // dispatch(all_advertisement_list(data));
    if(seasons?.statuscode !=200) dispatch(all_season_list(data));
  }, [user]);
  const series = useSelector((state) => state.webseries.series);

  useMemo(() => {
    if (episodes?.statuscode == 200) {
      const temp = tableData;
      const episodeSubtitle = [];
      episodes?.data?.map((value) => {
        // console.log(value, "value");
        const subtitle = {};
        value?.subtitle_list &&
          value?.subtitle_list?.map((ele, index) => {
            // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd");
            subtitle["subtitle_language_" + index] = ele?.subtitle_language;
            subtitle["subtitle_file_" + index] = ele?.subtitle_file;
            subtitle["subtitle_id_" + index] = ele?.id;
          });
        episodeSubtitle.push(subtitle);
      });

      // const episodeAudio = [];
      // episodes?.data?.map((value) => {
      //   // console.log(value, "value");
      //   const Audio = {};
      //   value?.audio_file_list &&
      //     value?.audio_file_list?.map((ele, index) => {
      //       // console.log(index, "idd");
      //       Audio["audio_language_" + index] = ele?.audio_language;
      //       Audio["audio_file_" + index] = ele?.audio_file;
      //       Audio["audio_id_" + index] = ele?.id;
      //       // console.log(index, ele?.id, "12301230");
      //     });
      //   episodeAudio.push(Audio);
      // });
      const episodeFile = [];
      episodes?.data?.map((value) => {
        // console.log(value, "value");
        const File = {};
        value?.episode_link_list &&
          value?.episode_link_list?.map((ele, index) => {
            // console.log(index, "idd");
            File["episode_language_" + index] = ele?.episode_language;
            File["episode_file_" + index] = ele?.episode_file;
            File["episode_id_" + index] = ele?.id;
            // console.log(index, ele?.id, "12301230");
          });
          episodeFile.push(File);
      });
      temp.tableBody = episodes?.data?.map((ele, index) => ({
        ...ele,
        // ...episodeAdvertise[index],
        ...episodeSubtitle[index],
        ...episodeFile[index],
        // ...episodeAudio[index],
        uploaded_by: ele?.series_name?.created_by?.firstName,
        series: ele?.series_name,
        episode_cast: ele?.episode_cast?.map((cast) => cast.cast_name),
        episode_genre: ele?.episode_genre?.map((genre) => genre.genre_title),
        season: ele?.season,
      }));
      setTableData({ ...temp });
    }
  }, [episodes]);
  // console.log(episodes,"123456")
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const handleAddSubtitle = () => {
    const temp = formStructure;
    if (formStructure[3].length - 4 < language?.data.length * 3) {
      // setForm({...form,subtitle_count:form?.subtitle_count+1})
      const randomName = (formStructure[3].length - 4) / 3;
      temp[3].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "subtitle_language_" + randomName,
          options: temp[3][5]["options"],
        },
        {
          id: "19",
          type: isEdit ? "lockedInput" : "inputBox",
          subtitle: "*",
          title: "Browse Subtitle file(.srt)",
          size: "5.5",
          name: "subtitle_file_" + randomName,
          endsWith: ".srt",
          errorText: "File extension should be .srt only",
          required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setSubtitlePosition(randomName);
			setsubTitleID(form["subtitle_id_" + randomName])
            setPopupContent(
              "Do you want to remove this subtitle from this Episode?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " subtitles in a Episode"
      );
    }
  };
  const handleAddAudioFile = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[2].length < language?.data.length * 3) {
      // console.log(form, "tr");

      const randomName = (formStructure[2].length) / 3;
      // console.log(randomName, "22222");

      temp[2].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "episode_language_" + randomName,
          options: temp[2][1]["options"],
        },
        {
          id: "3",
          type: isEdit ? "lockedInput" : "inputBox",
          subtitle: "*",
          title: "Upload m3u8 file link",
          endsWith: ".m3u8",
          // endsWith1: ".wav",
          errorText: "File extension should be .m3u8 only",
          name: "episode_file_" + randomName,
          size: "5.5",
          required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setAudioPosition(randomName);
			setAudioFileId(form["episode_id_" + randomName])
            setPopupContent(
              "Do you want to remove this episode link file from this Episode?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " audio file in a Episode"
      );
    }
  };
  useMemo(() => {
    if (isEdit) {
      setView("create_new");

      if (form?.subtitle_list?.length > (formStructure?.[3]?.length - 4) / 3)
        form?.subtitle_list?.map(
          (ele) =>
            form?.subtitle_list?.length > (formStructure?.[3]?.length - 4)/ 3 &&
            handleAddSubtitle()
        );
      if (form?.episode_link_list?.length > (formStructure?.[2]?.length ) / 3)
        form?.episode_link_list?.map(
          (ele) =>
            form?.episode_link_list?.length >
              (formStructure?.[2]?.length ) / 3 && handleAddAudioFile()
        );
    } else {
      setView("view_all");

      setForm({
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      });
    }
    const temp = formStructure;
    tempFormStruct?.map((arr, i) =>
      arr?.map(
        (ele, j) =>
          !["select", "select_multiple"].includes(ele.type) &&
          (temp[i][j] = ele)
      )
    );
    // temp[4][0]["handleClick"] = handleClick;
    temp[3][4]["handleClick"] = handleAddSubtitle;
    temp[2][0]["handleClick"] = handleAddAudioFile;
    setFormStructure([...temp]);
  }, [isEdit, role, location]);

  const casts = useSelector((state) => state.masters.casts);
  // const genre = useSelector((state) => state.masters.genre);
  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = formStructure;
      temp[0][0]["options"] = series?.data?.map((series) =>series?.series_type == "Super Short Series" &&  series?.series_name).filter((e) => e);

      setFormStructure([...temp]);
    }
    if (series?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData["filterColumn"][0]["options"] = series?.data?.map(
        (series) => series?.series_name
      );

      setTableData({ ...tempTableData });
    }
  }, [series,form?.series_type ,view]);
  useMemo(() => {
    if (seasons?.statuscode == 200) {
      const temp = formStructure;
      temp[0][1]["options"] = seasons?.data?.map(
          (season) => season?.series_name == form?.series && season?.season
        )
        .filter((e) => e);

      setFormStructure([...temp]);
    }
    if (seasons?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData["filterColumn"][1]["options"] = seasons?.data;
      setTableData({ ...tempTableData });
    }
  }, [seasons, form?.series ,view]);
  // console.log(seasons, "858585");
  useMemo(() => {
    if (casts?.statuscode == 200) {
      const temp = formStructure;
      temp[0][7]["options"] = casts?.data?.map((cast) => cast?.cast_name);

      setFormStructure([...temp]);
    }
  }, [casts , view]);
  // useMemo(() => {
  //   if (genre?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[0][8]["options"] = genre?.data?.map((genre) => genre?.genre_title);

  //     setFormStructure([...temp]);
  //   }
  // }, [genre ,view]);

  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;

      temp[3][5]["options"] = language?.data?.map(
        (language) => language?.language_name
      );
      temp[2][1]["options"] = language?.data?.map(
        (language) => language?.language_name
      );
      setFormStructure([...temp]);
    }
  }, [language , view]);

  useMemo(() => {
    if (form?.subtitles == "Active") {
      const temp = formStructure;
      temp[3]?.map((ele, index) => index >= 4 && (ele["display"] = "block"));
      setForm({ ...form, subtitle_count: 1 });
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[3]?.map((ele, index) => index >= 4 && (ele["display"] = "none"));
      setFormStructure([...temp]);
    }
  }, [form?.subtitles ,view]);
  // useMemo(() => {
  //   if (form?.audio_language == "Active") {
  //     const temp = formStructure;
  //     temp[2].map((ele, index) => index >= 2 && (ele["display"] = "block"));
  //     setForm({ ...form });
  //     setFormStructure([...temp]);
  //   } else {
  //     const temp = formStructure;
  //     temp[2].map((ele, index) => index >= 2 && (ele["display"] = "none"));
  //     setFormStructure([...temp])
  //   }
  // }, [form?.audio_language]);
  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][5]["display"] = "block";
      temp[0][6]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][5]["display"] = "none";
      temp[0][6]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [form?.publish_type,view]);

  const message = useSelector((state) => state.layout.message);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(form, "6565");
    // const advertise = [];
    // for (var i = 0; i < formStructure[4].length / 7; i++) {
    //   advertise.push({
    //     advertise: advertisements?.data
    //       .map(
    //         (advertisement) =>
    //           advertisement?.product_name == form?.["advertise" + i] &&
    //           advertisement?.id
    //       )
    //       .filter((e) => e)[0],
    //     time: form?.["time" + i],
    //   });
    // }
    const tempForm = form;
    // console.log(form,"nreeeeee")
    tempForm["tempseries"] = form?.["series"];
    tempForm["episode_link"] = "Active";
    tempForm["tempepisode_cast"] = form?.["episode_cast"];
    // tempForm["tempepisode_genre"] = form?.["episode_genre"];

    tempForm["tempseason"] = form?.["season"];
    tempForm["series"] = series?.data
    ?.map(
      (option) =>
        form?.["series"]?.includes(option.series_name) && option.id
    )
    .filter((e) => e);
  tempForm["episode_cast"] = casts?.data
    ?.map(
      (option) =>
        form?.["episode_cast"]?.includes(option.cast_name) && option.id
    )
    .filter((e) => e);
    // tempForm["episode_genre"] = genre?.data
    // ?.map(
    //   (option) =>
    //     form?.["episode_genre"]?.includes(option.genre_title) && option.id
    // )
    // .filter((e) => e);
   
  tempForm["season"] = seasons?.data?.map((option) =>
    form?.["series"] == option?.series ? form?.["season"]?.includes(option?.season) && option?.id : null).filter((e) => e);

  tempForm["uploaded_by"] = user?.id;
  tempForm["audio_language"]="Active"
    tempForm["series_type"] = "Super Short Series"
    // tempForm["advertise"] = JSON.stringify(advertise);
    setForm({
      ...tempForm,
    });

    const data = new FormData();
    Object.keys(form)?.map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure?.map((structure) =>
      structure?.map((element) => {
        if (
          element.type == "image" &&
          form?.[element.name] &&
          typeof form?.[element.name] != "string"
        ) {
          const temp = form;
          temp["temp" + element.name] = form?.[element.name];
          temp[element.name] = URL.createObjectURL(form?.[element.name]);

          setForm({
            ...temp,
          });
        }
      })
    );
    const temp = tableData;
    if (isEdit) {
      dispatch(episode_update(data));
    } else {
      dispatch(episode_create(data));
    }
    setFlag(true);
  };
  // console.log(episodes, "654654");
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody?.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });

      setIsEdit(false);
      setForm({
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      });
      setFlag(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        data.append("series_type","Super Short Series")
        dispatch(all_episode_list(data));
        navigate("/SSEpisode/SSEpisode/", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
      setView("view_all");
    } else {
      const tempForm = form;
      tempForm["series"] = form?.tempseries;
      tempForm["series_cast"] = form?.tempseries_cast;
      tempForm["season"] = form?.tempseason;
      tempForm["episode_thumbnail"] = form?.tempepisode_thumbnail;
      tempForm["episode_poster"] = form?.tempepisode_poster;
      setForm({ ...tempForm });
    }
  }, [message]);

  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;
    setForm({ ...form, episode_duration: hDisplay + mDisplay + sDisplay });
  };

  useMemo(() => {
    if (result) {
      const temp = formStructure;
    // if (subtitlePosition != undefined) {
    //     temp[3]?.map((value, index) => {
    //       if (value.position == subtitlePosition) {
    //         const tempForm = form;
    //         tempForm["subtitle_language_" + value.position] = "";
    //         tempForm["subtitle_file_" + value.position] = "";
    //         setForm({ ...tempForm });
    //         temp[3].splice(index - 2, 3);
    //       }
    //     });
    //     setFormStructure([...temp]);
    //     setSubtitlePosition(undefined);
    //     dispatch(subtitle_delete({ id: subTitleID, video_type: "Episode" }));

    //   } else if (audioPosition != undefined) {
    //     temp[2]?.map((value, index) => {
    //       if (value.position == audioPosition) {
		// 	// console.log(value.position, "8888")
    //         const tempForm = form;
    //         tempForm["audio_file_" + value.position] = "";
    //         tempForm["audio_language_" + value.position] = "";
    //         setForm({ ...tempForm });
    //         temp[2].splice(index - 2, 3);
    //       }
    //     });
    //     setFormStructure([...temp]);
    //     setSubtitlePosition(undefined);
    //     dispatch(audio_file_delete({ id: audioFileId, video_type: "Episode" }));

    //   }
    
    // }
    if (subtitlePosition != undefined) {
      var scount = 1;
      temp[3].map((value, index) => {
        if (
          value.position >= subtitlePosition &&
          !temp[3][temp[3].length - 1]["isLast"]
        ) {
          // console.log(
          //   value.position,
          //   subtitlePosition,
          //   !temp[3][temp[3].length - 1]["isLast"],
          //   "8888"
          // );
          const tempForm = form;
          if (index < temp[3].length - 3) {
            tempForm["subtitle_file_" + value.position] =
              tempForm["subtitle_file_" + (Number(value.position) + 1)];
            tempForm["subtitle_language_" + value.position] =
              tempForm["subtitle_language_" + (Number(value.position) + 1)];
          } else {
            tempForm["subtitle_file_" + value.position] = "";
            tempForm["subtitle_language_" + value.position] = "";
          }

          setForm({ ...tempForm });
        }
      });
      temp[3].map((value, index) => {
        if (value.type == "button" && index > 6) {
          temp[3][index - 1]["name"] = "subtitle_file_" + scount;
          temp[3][index - 2]["name"] = "subtitle_language_" + scount;
          temp[3][index]["position"] = scount;
          // if(index == temp[2].length -1 ){
          //   temp[2][index]["isLast"] = true

          // }
          scount++;
        }
      });
      console.log(temp[3], temp[3].length, "testing4");
      if (!temp[3][temp[3].length - 1]["isLast"]) {
        temp[3].splice(temp[3].length - 3, 3);
      }
      temp[3][temp[3].length - 1]["isLast"] = true;
      // setFormStructure([...temp]);
      setFormStructure([...temp]);
      setSubtitlePosition(undefined);
      dispatch(subtitle_delete({ id: subTitleID, video_type: "Episode" }));
      // console.log(form, "idssss")
    }
    else if (audioPosition != undefined) {
      var count = 1;
      temp[2].map((value, index) => {
        if (
          value.position >= audioPosition &&
          !temp[2][temp[2].length - 1]["isLast"]
        ) {
          // console.log(
          //   value.position,
          //   audioPosition,
          //   !temp[2][temp[2].length - 1]["isLast"],
          //   "8889"
          // );
          // console.log(value.position, "8888")]
          const tempForm = form;
          if (index < temp[2].length - 3) {
            tempForm["episode_file_" + value.position] =
              tempForm["episode_file_" + (Number(value.position) + 1)];
            tempForm["episode_language_" + value.position] =
              tempForm["episode_language_" + (Number(value.position) + 1)];
          } else {
            tempForm["episode_file_" + value.position] = "";
            tempForm["episode_language_" + value.position] = "";
          }

          setForm({ ...tempForm });
        }
      });

      temp[2].map((value, index) => {
        if (value.type == "button" && index > 2) {
          temp[2][index - 1]["name"] = "episode_file_" + count;
          temp[2][index - 2]["name"] = "episode_language_" + count;
          temp[2][index]["position"] = count;
          // if(index == temp[2].length -1 ){
          //   temp[2][index]["isLast"] = true

          // }
          count++;
        }
      });
      // console.log(temp[2], temp[2].length, "testing3");
      if (!temp[2][temp[2].length - 1]["isLast"]) {
        temp[2].splice(temp[2].length - 3, 3);
      }
      temp[2][temp[2].length - 1]["isLast"] = true;
      setFormStructure([...temp]);

      setAudioPosition(undefined);
      // dispatch(audio_file_delete({ id: audioFileId, video_type: "Episode" }));
      dispatch(video_link_delete({ id: audioFileId ,video_type : "Episode" }));

    }
  }
    setResult(undefined);
  }, [result]);
  useEffect(() => {
    const temp = formStructure;
    // temp[4][0]["handleClick"] = handleClick;
    temp[3][4]["handleClick"] = handleAddSubtitle;
    temp[2][0]["handleClick"] = handleAddAudioFile;
    setFormStructure([...temp]);
  }, [view]);

  

  const handleClose = () => {
    setOpenAdError(false);
  };

  useMemo(() => {
    var temp = form;

    if (isEdit) {
      var flag = true;
      for (var i = 0; i < episodes?.data.length; i++) {
        if (episodes?.data[i]?.id == form?.id) {
          if (
            episodes?.data[i]?.series_name == form?.series
          ) {
            flag = false;
          }
          break;
        }
      }

      if (flag) {
        temp = {
          subtitles: "Inactive",
          episode_is_for18Plus: "No",
          allow_download: "Yes",
          audio_language: "Inactive",
        };
        temp["id"] = form?.id;
        temp["series"] = form?.series;
      }
    } else {
      temp = {
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      };
      temp["serie"] = form?.series;
      // temp["season"]=form?.season
    }

    series?.data.filter((series) => {
      if (form?.series_name == series?.series_name)
        temp["series_type"] = series?.series_type;
    });

    setForm({ ...temp });
  }, [form?.series_name]);
  const subtitles = language?.data?.map((ele, index) =>
    eval("form?.subtitle_language_" + index)
  );
  const audioFile = language?.data?.map((ele, index) =>
    eval("form?.episode_language_" + index)
  );
  useMemo(() => {
    const newLanguageList = language?.data
      ?.map(
        (ele, index) =>
          !subtitles.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const newAudioList = language?.data
      ?.map(
        (ele, index) =>
          !audioFile.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const temp = formStructure;
    temp[3]?.map(
      (ele, index) =>
        ele?.type == "select" && (temp[3][index]["options"] = newLanguageList)
    );
    temp[2]?.map(
      (ele, index) =>
        ele?.type == "select" && (temp[2][index]["options"] = newAudioList)
    );
    setFormStructure([...temp]);
  }, [form]);
  return (
    <>
      <video
        style={{ display: "none" }}
        id="myvid"
        src={form?.episodeMp4_URL}
        ref={videoEl}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadata}
      ></video>
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
 {role !== "Distributor" &&     <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/SSEpisode/CreateSSEpisode/"}
        view_all={"/SSEpisode/SSEpisode/"}
        access={rights?.["Episode"]?.["create"] == "true"}
        form={form}
      
        export_excel={
          <Export
            fileName={"Short Episode"}
            access={rights?.["Episode"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead?.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}

        
      />}

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/SSEpisode/EditSSEpisode/"}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
